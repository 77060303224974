<template>
    <div class="content-box">
        <div class="hint">
            <div class="top-title">如遇险情,请保持冷静</div>
            <div class="moddle-title">确保自身安全的情况下寻求帮助</div>
        </div>

        <div class="railway-line">
            <div class="ra-title">安全专线</div>
            <div class="buttons">
                <div class="child-box">
                    <van-button color="#4873D3" icon="envelop-o"
                                type="info" @click="note" class="child-button">短信求助</van-button>
                    <van-button color="#4873D3" icon="phone-o"
                                type="info" @click="dial" class="child-button">拨打110</van-button>
                </div>
                <van-button color="#4873D3" plain type="info"
                            @click="show=true" class="bottom-but">呼叫安全专线 | 7*24小时随时接听</van-button>
            </div>
        </div>
        <van-action-sheet
                v-model="show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @select="onSelect"
                @cancel="onCancel"
        />

    </div>
</template>

<script>
    import { sendSms } from '@/utils/api';
    import { Dialog } from 'vant';
    export default {
        name: "Safety",
        data(){
            return{
                show:false,
                actions: [{ name: '拨打400-032-5263' }],
            }
        },
        methods:{
            onSelect(item){
                console.log('选择的是?',item)
                window.location.href = 'tel:400-032-5263';
                sendSms({smsType:'saveTel'}).then(res=>{
                    console.log('安全专线:',res)
                })
            },
            onCancel(){ },
            note(){
                Dialog.confirm({
                    title: '提示',
                    message: '系统将发送紧急求助短信至村口壹站运营人员，请确定是否发送 ?',
                    confirmButtonColor:'#2BC28E',
                    messageAlign:'left'
                })
                .then(() => {
                    // on confirm
                    sendSms({smsType:'smsHelp'}).then(res=>{
                        console.log('短信求助:',res)
                    })
                })
                .catch(() => {
                    // on cancel
                });
            },
            dial(){
                Dialog.confirm({
                    title: '确认拨打110报警么？',
                    message: '  虚假报警将承担法律责任，处以拘留，谨慎使用。报警后，系统将发送短信至村口壹站运营联系人。',
                    confirmButtonColor:'#2BC28E',
                    confirmButtonText:'确认拨打',
                    cancelButtonText:'再想想',
                    messageAlign:'left'
                })
                .then(() => {
                    // on confirm
                    window.location.href = 'tel:110';
                    sendSms({smsType:'policeTel'}).then(res=>{
                        console.log('报警专线:',res)
                    })
                })
                .catch(() => {
                    // on cancel
                });

            }


        }
    }
</script>

<style lang="less" scoped>
    .content-box{
        padding: 42px 0;
        .hint{
            width: 90%;
            height: 140px;
            background: #2BC28F;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            .top-title{
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                margin-bottom: 15px;
            }
            .moddle-title{
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
            margin-bottom: 28px;
        }
        .railway-line{
            padding-top: 10px;
            width: 90%;
            margin: 0 auto;
            .ra-title{
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
            }
            .buttons{
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                padding: 10px;
                .child-box{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    .child-button{
                        width: 150px;
                        height: 48px;
                        line-height: 48px;
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        border-radius: 4px;

                    }
                }
                .bottom-but{
                    border-radius: 4px;
                    font-size: 16px;
                }
            }
        }
    }


</style>
